<template>
  <v-card flat class="fill-height">
    <v-card-title >
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        :label="inputLang.search.label"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-card-text class="fill-height">
      <!-- 
        :options.sync="pagination.options"
        :items-per-page="pagination.itemsPerPage"
        :server-items-length="pagination.totalItems"
        @page-count="pagination.page = $event"
        hide-default-footer
       -->
      <v-data-table
        :loading="titlesLoading"
        :item-class="()=>{return 'clickable'}"
        multi-sort
        :sort-by.sync="titleTableCfg.sort.sortBy"
        :sort-desc.sync="titleTableCfg.sort.sortDesc"
        
        :items-per-page="50"
        :headers="tableData.headers"
        :items="tableData.rows"
        :search="search"
        @click:row="editItem"
        item-key="titleId"
        group-by="type"
      >
        <template v-slot:no-data >
          <v-alert border="left" dense type="info" color="shaza_light_blue">{{lang.titleTable.noData}}</v-alert>
        </template>
        <template v-slot:item.images="{item}">
          <td class='text-center'>
            <v-img v-if="item.images && item.images[0]" class="ma-3" :width="50" :height="50" :src="`https://shazacin-static-content.s3-eu-west-1.amazonaws.com/${item.images[0]}`" @error="item.images = []"></v-img>
            <v-icon v-else class="ma-4" color="grey" x-large >mdi-image-off-outline</v-icon>
          </td>
        </template>
        <template v-slot:item.title="{item}">
          <td>{{item.title}}</td>
        </template>
        <template v-slot:item.publicEnabled="{item}">
          <td>
            <v-icon v-if="item.publicEnabled" color="success">mdi-check-circle-outline</v-icon>
            <v-icon v-else color="error">mdi-close-circle-outline</v-icon>
          </td>
        </template>
        <template v-slot:item.fingerprinting_progress="{item}">
          <td>
            <span v-if="['book', 'series', 'chapter'].includes(item.type)">--</span>
            <v-icon v-else-if="!!item.fingerprinting_progress && item.fingerprinting_progress === 'completed'" color="success">mdi-check-circle-outline</v-icon>
            <span v-else-if="!!item.fingerprinting_progress && item.fingerprinting_progress == 'notRequired'">Missing audio</span>
            <span v-else-if="!item.fingerprinting_progress && item.fingerprinting_progress !== 'completed'">--</span>
            <v-progress-circular v-else indeterminate width="2" size="20" color="shaza_light_blue" />
          </td>
        </template>
        <template v-slot:item.categories="{item}">
          <td>
            <v-chip-group column v-if="item.categories">
              <v-chip color="shaza_dark_blue" dark label small v-for="(cat, i) in item.categories" :key="`categories_${item.title}_${i}`">
                {{ cat }}
              </v-chip>
            </v-chip-group>

            <span ></span>
          </td>
        </template>
        <template v-slot:item.type="{item}">
          <td>{{item.type}}</td>
        </template>
        <template v-slot:item.createdAt="{item}">
          <td>{{formatDate(item.createdAt)}}</td>
        </template>
        <template v-slot:item.updatedAt="{item}">
          <td>{{formatDate(item.updatedAt)}}</td>
        </template>
      </v-data-table>
      <!-- <div class="text-right pt-2">
        <v-pagination
          v-model="pagination.page"
          :length="pagination.pageCount"
        ></v-pagination>
      </div> -->
    </v-card-text>
    <!-- <v-card-actions >
      <v-spacer/>
      <v-btn small color="shaza_yellow" class="elevation-0 white--text" @click="addNewTitle">{{buttonLang.addNewTitle.label}}</v-btn>
    </v-card-actions> -->
    <v-footer app padless style="z-index: 10;" class="shaza_dark_blue">
      <v-col class="text-right ma-0 pa-0" cols="12">
        <!-- <v-btn small dark class="elevation-0" text v-show="!!$routerHistory.previous().path" :to="{ path: $routerHistory.previous().path }">
          <v-icon>chevron_left</v-icon>
          {{buttonLang.back.label}}
        </v-btn> -->
        <v-btn small class="elevation-0" color="shaza_yellow" @click="addNewTitle" aria-label="Add title">{{buttonLang.addNewTitle.label}}
          <v-icon class="ml-3">add_circle</v-icon>
        </v-btn>
      </v-col>
    </v-footer>

  </v-card>
</template>

<script>

export default {
  name: 'TrackUploader',
  data() {
    return {
      //titles list
      titlesLoading: true,
      titles: [],
      editorState: '',
      pagination: {
        options: {},
        page: 1,
        itemsPerPage: 5,
        pageCount: 2,
        previousTokens: [],
        nextToken: null
      },
      titleTableCfg: {
        sort: {
          sortBy: ['updatedAt', 'publicEnabled'],
          sortDesc: [true, true]
        },
        displayVals: [
          {
            prop: 'images',
            sortable: false,
          },
          {
            prop: 'title',
            sortable: true,
          },
          {
            prop: 'type',
            sortable: true,
          },
          {
            prop: 'year',
            sortable: true,
          },
          {
            prop: 'categories',
            sortable: false,
          },
          {
            prop: 'fingerprinting_progress',
            sortable: true,
          },
          {
            prop: 'publicEnabled',
            sortable: true,
          },
          {
            prop: 'createdAt',
            sortable: true,
          },
          {
            prop: 'updatedAt',
            sortable: true,
          },
          /* {
            prop: 'genre',
            sortable: false,
          },
          {
            prop: 'actors',
            sortable: false,
          },
          {
            prop: 'director',
            sortable: false,
          },
          {
            prop: 'previewUrl',
            sortable: false,
          },
          {
            prop: 'rated',
            sortable: false,
          },
          {
            prop: 'released',
            sortable: false,
          },
          {
            prop: 'runtimeMinutes',
            sortable: false,
          },
          {
            prop: 'score',
            sortable: false,
          },
          {
            prop: 'synopsis',
            sortable: false,
          },
          {
            prop: 'writer',
            sortable: false,
          } */
        ],
      },
      subscriptions: {},
      search: null,
    }
  },
  computed:{
    lang(){
      return this.$t('pages.TrackUploader');
    },
    tableData(){
      let self = this;
      let headers = [], rows = [];
      let items = [...self.titles];
      if(items.length !== 0){
         headers = [...Object.keys(items[0]).sort((a, b) => 
          self.titleTableCfg.displayVals.findIndex(aa => aa.prop === a) - self.titleTableCfg.displayVals.findIndex(bb => bb.prop === b)
        ).map(prop=>{
          let propCfg = self.titleTableCfg.displayVals.find(o=> prop === o.prop);
          if(propCfg){
            return {
              text: self.lang.titleTable.headers[prop],
              align: 'start',
              sortable: propCfg.sortable,
              value: propCfg.prop,
            }
          }
        }).filter(o=> !!o)];

        rows = [...items];
      }

      return { headers, rows }
    },
    titleTypes(){
      let self = this;
       return Object.keys(self.lang.titleTypes).map(o=>({
         value: o,
         text: self.lang.titleTypes[o] || o
       }))
    },
    years(){
      let oldestMovie = 1888;
      let current = new Date().getFullYear();
      let years = [current];
      for(let i = (current-oldestMovie); i >= 0; i--){
        years.push(oldestMovie+i);
      }
      return years;
    }
  },
  methods:{
    async getTitles(){
      let self = this;
      self.titles = await self.getAllTitles();
      self.titlesLoading = false;
    },
    async editItem(item){
      console.log('editItem', item);
      this.$router.push({path: `/TrackEditor/update/${item.titleId}?`});
    },
    addNewTitle(){
      this.$router.push({path: '/TrackEditor/create' })
    },
    // subscribeToTitleChanges() {
    //   const self = this
    //   console.log('allTitlesUpdatesSubscription subscribeToTitleChanges')
    //   const subscription = self.$Amplify.API.graphql(
    //     self.$Amplify.graphqlOperation(allTitlesUpdatesSubscription)
    //   ).subscribe({
    //     // next: self.handleIncomingSubscriptionUpdate,
    //     next: (data) => {
    //       let errors = data?.value?.data?.errors
    //       if (errors?.length > 0) {
    //         console.error('Error occurred during subscription update: ', errors)
    //         return
    //       }
    //       let updatedItem = data?.value?.data?.allTitlesUpdatesSubscription
    //       console.info(`allTitlesUpdatesSubscription subscription returned:`, updatedItem)

    //       let itemIndex = self.titles.findIndex(item => item.titleId == updatedItem.titleId)

    //       if (itemIndex >= 0) {
    //         self.titles[itemIndex] = {...updatedItem, ...self.titles[itemIndex]}
    //       }else{
    //         console.log('new item added');
    //       }
    //       console.warn(`The item AFTER update is............. `, self.titles[itemIndex])
    //       //! Ensure items are updated!!!
    //       self.titles = [...self.titles]
    //     },
    //     error: self._glob_handleIncomingGqlSubscriptionError
    //   });
    // }
    
  },
  async mounted(){
    this.getTitles();
    console.log('allTitlesUpdatesSubscription');
    // this.subscribeToTitleChanges();
  },
  beforeDestroy(){
    if(this.subscriptions.titleData) this.subscriptions.titleData.unsubscribe();
  }
}
</script>
<style lang="scss">
  .tabel_grouping{
    display: block;
    width:100%;
    padding:2px;
  }
</style>