var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"fill-height",attrs:{"flat":""}},[_c('v-card-title',[_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.inputLang.search.label,"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-card-text',{staticClass:"fill-height"},[_c('v-data-table',{attrs:{"loading":_vm.titlesLoading,"item-class":function (){return 'clickable'},"multi-sort":"","sort-by":_vm.titleTableCfg.sort.sortBy,"sort-desc":_vm.titleTableCfg.sort.sortDesc,"items-per-page":50,"headers":_vm.tableData.headers,"items":_vm.tableData.rows,"search":_vm.search,"item-key":"titleId","group-by":"type"},on:{"update:sortBy":function($event){return _vm.$set(_vm.titleTableCfg.sort, "sortBy", $event)},"update:sort-by":function($event){return _vm.$set(_vm.titleTableCfg.sort, "sortBy", $event)},"update:sortDesc":function($event){return _vm.$set(_vm.titleTableCfg.sort, "sortDesc", $event)},"update:sort-desc":function($event){return _vm.$set(_vm.titleTableCfg.sort, "sortDesc", $event)},"click:row":_vm.editItem},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-alert',{attrs:{"border":"left","dense":"","type":"info","color":"shaza_light_blue"}},[_vm._v(_vm._s(_vm.lang.titleTable.noData))])]},proxy:true},{key:"item.images",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},[(item.images && item.images[0])?_c('v-img',{staticClass:"ma-3",attrs:{"width":50,"height":50,"src":("https://shazacin-static-content.s3-eu-west-1.amazonaws.com/" + (item.images[0]))},on:{"error":function($event){item.images = []}}}):_c('v-icon',{staticClass:"ma-4",attrs:{"color":"grey","x-large":""}},[_vm._v("mdi-image-off-outline")])],1)]}},{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.title))])]}},{key:"item.publicEnabled",fn:function(ref){
var item = ref.item;
return [_c('td',[(item.publicEnabled)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-check-circle-outline")]):_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-close-circle-outline")])],1)]}},{key:"item.fingerprinting_progress",fn:function(ref){
var item = ref.item;
return [_c('td',[(['book', 'series', 'chapter'].includes(item.type))?_c('span',[_vm._v("--")]):(!!item.fingerprinting_progress && item.fingerprinting_progress === 'completed')?_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-check-circle-outline")]):(!!item.fingerprinting_progress && item.fingerprinting_progress == 'notRequired')?_c('span',[_vm._v("Missing audio")]):(!item.fingerprinting_progress && item.fingerprinting_progress !== 'completed')?_c('span',[_vm._v("--")]):_c('v-progress-circular',{attrs:{"indeterminate":"","width":"2","size":"20","color":"shaza_light_blue"}})],1)]}},{key:"item.categories",fn:function(ref){
var item = ref.item;
return [_c('td',[(item.categories)?_c('v-chip-group',{attrs:{"column":""}},_vm._l((item.categories),function(cat,i){return _c('v-chip',{key:("categories_" + (item.title) + "_" + i),attrs:{"color":"shaza_dark_blue","dark":"","label":"","small":""}},[_vm._v(" "+_vm._s(cat)+" ")])}),1):_vm._e(),_c('span')],1)]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.type))])]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(_vm.formatDate(item.createdAt)))])]}},{key:"item.updatedAt",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(_vm.formatDate(item.updatedAt)))])]}}])})],1),_c('v-footer',{staticClass:"shaza_dark_blue",staticStyle:{"z-index":"10"},attrs:{"app":"","padless":""}},[_c('v-col',{staticClass:"text-right ma-0 pa-0",attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"elevation-0",attrs:{"small":"","color":"shaza_yellow","aria-label":"Add title"},on:{"click":_vm.addNewTitle}},[_vm._v(_vm._s(_vm.buttonLang.addNewTitle.label)+" "),_c('v-icon',{staticClass:"ml-3"},[_vm._v("add_circle")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }